
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { format } from 'date-fns';
import Checkbox from '@material-ui/core/Checkbox';
import { v4 as uuidv4 } from 'uuid';
import { Divider, List, ListItem, ListItemText, ListItemSecondaryAction, Modal, makeStyles, AccordionSummary, AccordionDetails, Grid, Toolbar, AppBar, IconButton, Badge } from '@material-ui/core';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import useLongPress from './hooks/useLongPress';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Menu from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
interface CreateReminder {
  dueDate: string;
  textInput: string;
}
interface Reminder {
  textInput: string;
  id: string;
}
interface ReminderUpdate {
  dueDate: string;
  textInput: string;
  complete: boolean;
  id: string;
}
interface DailyReminders {
  dueDate: string;
  todoReminders: Reminder[];
  completedReminders: Reminder[];
}

// deprecated
interface DepReminder {
  dueDate: string;
  textInput: string;
  complete: boolean;
  id: string;
}

// cleanup old data model
function cleanup() {
  const keys = Object.keys(localStorage);
  keys.filter(x => /\d{4}-\d{2}-\d{2}/.test(x)).forEach(x => {
    const valueJson = localStorage.getItem(x);
    if (x) {
      const oldValue = JSON.parse(valueJson || '');
      if (Array.isArray(oldValue)) {
        let old: DepReminder[] = oldValue;
        localStorage.setItem(x, JSON.stringify({
          dueDate: x,
          completedReminders: old.filter(x => x.complete).map(x => ({ ...x, id: x.id ?? uuidv4() })),
          todoReminders: old.filter(x => !x.complete).map(x => ({ ...x, id: x.id ?? uuidv4() })),
        } as DailyReminders))
      }
    }
  });
  const current = format(new Date(), 'yyyy-MM-dd');

  const movedTodos = keys.filter(x => /\d{4}-\d{2}-\d{2}/.test(x)).flatMap(x => {
    const valueJson = JSON.parse(localStorage.getItem(x) || '') as DailyReminders;

    if (valueJson.dueDate < current && valueJson.todoReminders.length > 0) {
      localStorage.setItem(x, JSON.stringify({
        ...valueJson,
        todoReminders: [],
      } as DailyReminders));
      return valueJson.todoReminders;
    }
    return [];
  });

  let valueJson = {
    dueDate: current,
    completedReminders: [],
    todoReminders: [],
  } as DailyReminders;

  const json = localStorage.getItem(current);
  if (json) {
    valueJson = JSON.parse(json) as DailyReminders;
  }
  valueJson.todoReminders = [...valueJson.todoReminders, ...movedTodos];

  localStorage.setItem(current, JSON.stringify(valueJson));

}

function getByKey(dateKey: string) {
  let valueJson = {
    dueDate: dateKey,
    completedReminders: [],
    todoReminders: [],
  } as DailyReminders;

  const json = localStorage.getItem(dateKey);
  if (json) {
    valueJson = JSON.parse(json) as DailyReminders;
  }
  return valueJson;
}


function App() {

  useEffect(() => { cleanup() }, []);

  const current = format(new Date(), 'yyyy-MM-dd');
  const valueJson = getByKey(current);
  const [reminders, setReminders] = React.useState(valueJson);
  const [expanded, setExpanded] = React.useState(false);


  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      flexGrow: 1,
    },
    heading: {
      fontSize: theme.typography.pxToRem(20),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    menuButton: {
      marginRight: theme.spacing(2),
      visibility: 'hidden'
    },
    title: {
      flexGrow: 1,
    },
    header: {
      marginBottom: '10px',
    },
    body: {
      width: '98%',
      marginLeft: '1%',
    }
  }));
  const classes = useStyles();


  const handleUpdate = (updatedReminder: ReminderUpdate) => {

    const valuesJson = localStorage.getItem(updatedReminder.dueDate) || '';
    let currentList: DailyReminders = {
      completedReminders: [],
      todoReminders: [],
      dueDate: updatedReminder.dueDate,
    };
    if (valuesJson) {
      currentList = JSON.parse(valuesJson) as DailyReminders;
    }

    let [listToRemove, listToAdd] = updatedReminder.complete ? [currentList.todoReminders, currentList.completedReminders] : [currentList.completedReminders, currentList.todoReminders]

    listToRemove = listToRemove.filter(x => x.id !== updatedReminder.id);

    if (!listToAdd.some(x => x.id === updatedReminder.id)) {
      // add it
      listToAdd = [...listToAdd, updatedReminder];
    } else {
      // update existing
      const current = listToAdd.findIndex(x => x.id === updatedReminder.id);
      listToAdd[current] = updatedReminder;
    }

    currentList.todoReminders = updatedReminder.complete ? listToRemove : listToAdd;
    currentList.completedReminders = updatedReminder.complete ? listToAdd : listToRemove;


    updateReminders(currentList);
  }

  const handleComplete = (reminder: ReminderUpdate) => {
    const newList = {
      completedReminders: reminder.complete ? [...reminders.completedReminders, reminder] : reminders.completedReminders.filter(x => x.id !== reminder.id),
      todoReminders: !reminder.complete ? [...reminders.todoReminders, reminder] : reminders.todoReminders.filter(x => x.id !== reminder.id),
      dueDate: reminders.dueDate,
    };
    updateReminders(newList);
  }

  const updateReminders = (reminders: DailyReminders) => {
    setReminders(reminders);
    localStorage.setItem(reminders.dueDate, JSON.stringify(reminders));
  }

  return (
    <div className="App">
      <AppBar position="static" className={classes.header}>
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <Menu />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Daily Planner
      </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.body}>
        <ReminderForm
          saveLabel={'Add Reminder'}
          handleUpdate={handleUpdate}
          handleDateChange={(date) => date !== reminders.dueDate && setReminders(getByKey(date))}
          defaultValues={{
            dueDate: reminders.dueDate,
            textInput: ''
          }} />

        <ToDoItemList
          complete={false}
          dueDate={reminders.dueDate}
          reminders={reminders.todoReminders}
          handleComplete={handleComplete}
          handleUpdate={(todoReminders) => updateReminders({ ...reminders, todoReminders })} />
        <Accordion expanded={expanded} onChange={(_, expanded) => setExpanded(expanded)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className={classes.heading}>
              <Badge
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                badgeContent={reminders.completedReminders.length}
                color="secondary" >
              </Badge>

              <span style={{ marginLeft: '12px' }}>
                Completed</span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ToDoItemList
              complete={true}
              dueDate={reminders.dueDate}
              reminders={reminders.completedReminders}
              handleComplete={handleComplete}
              handleUpdate={(completedReminders) => updateReminders({ ...reminders, completedReminders })} />
          </AccordionDetails>
        </Accordion>

      </div>

    </div>
  );
}

const grid = 8;
const getListStyle = (isDraggingOver: boolean) => ({
  paddingBottom: isDraggingOver ? '65px' : undefined,
});

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  paddingLeft: grid * 2,
  paddingRight: grid * 2,
  paddingTop: grid * 2,

  // change background colour if dragging 

  // styles we need to apply on draggables
  ...draggableStyle
});
function reorder<T>(list: T[], startIndex: number, endIndex: number) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const ToDoItemList = (props: {
  reminders: Reminder[],
  complete: boolean,
  dueDate: string,
  handleUpdate: (reminder: Reminder[]) => void,
  handleComplete: (reminder: ReminderUpdate) => void,
}) => {
  const { reminders, handleUpdate, complete } = props;
  const [isDragging, setIsDragging] = React.useState(false);
  const handleReminderUpdate = (reminder: ReminderUpdate, index: number) => {

    if (reminder.dueDate === props.dueDate) {
      props.handleUpdate([...reminders.slice(0, index), reminder, ...reminders.slice(index + 1)])
    }
    else {
      props.handleUpdate(reminders.filter(x => x.id !== reminder.id));
      const otherVal = getByKey(reminder.dueDate);
      if (reminder.complete) {
        otherVal.completedReminders = [...otherVal.completedReminders, reminder];
      } else {
        otherVal.todoReminders = [...otherVal.todoReminders, reminder];
      }
      localStorage.setItem(otherVal.dueDate, JSON.stringify(otherVal));
    }
  }

  const onDragEnd = (result: DropResult) => {
    if (result.destination) {
      handleUpdate(reorder(reminders, result.source.index, result.destination.index));
    }
    setIsDragging(false);
  }
  const handleRemoveReminder = (props: { id: string }) => {
    handleUpdate(reminders.filter(x => x.id !== props.id));
  }
  return (
    <React.Fragment>
      <List component="nav" style={{ width: '100%' }}>
        <DragDropContext
          onDragEnd={onDragEnd}

          onDragStart={() => { setIsDragging(true); }}>
          <Droppable droppableId={`complete:${complete}`} >
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}>
                { props.reminders?.map((reminder, index) =>
                  <Draggable key={reminder.id} draggableId={reminder.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}>
                        <ToDoItem
                          complete={props.complete}
                          isDragging={isDragging}
                          reminder={reminder}
                          handleComplete={(r) => props.handleComplete({
                            id: r.id,
                            complete: r.complete,
                            dueDate: props.dueDate,
                            textInput: reminder.textInput,
                          })}
                          dueDate={props.dueDate}
                          update={(updatedReminder) => handleReminderUpdate(updatedReminder, index)}
                          removeReminder={handleRemoveReminder} />
                      </div>
                    )}
                  </Draggable>
                )}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </List>
    </React.Fragment>
  );
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    maxWidth: '90%',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2, 3),
  },
}));
const ToDoItem = (props: {
  reminder: Reminder,
  dueDate: string,
  complete: boolean,
  isDragging: boolean,
  handleComplete: (props: { id: string, complete: boolean }) => void,
  update: (reminder: ReminderUpdate) => void,
  removeReminder: (props: { id: string }) => void,
}) => {

  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const onLongPress = () => {
    console.log('show modal');
    if (!props.isDragging) {
      setIsModalOpen(true);
    } else {
      console.log('busy dragging')
    }
  }
  const longPressEvent = useLongPress(onLongPress, () => { }, {
    shouldPreventDefault: true,
    delay: 500,
  });
  const classes = useStyles();

  const { reminder, complete, dueDate } = props;
  const [checked, setChecked] = React.useState(complete);
  return (
    <React.Fragment>
      <ListItem {...longPressEvent} button>
        <ListItemText primary={reminder.textInput} />
        <ListItemSecondaryAction>
          <Checkbox checked={checked} onClick={() => {
            const complete = !checked;
            setChecked(complete);
            setTimeout(() =>
              props.handleComplete({
                id: reminder.id,
                complete: complete,
              }), 250)
          }} />
        </ListItemSecondaryAction>

      </ListItem>
      <Divider light />
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      ><div className={classes.paper} style={modalStyle}>

          <ReminderForm
            saveLabel={'Save'}
            deleteLabel={'Delete'}
            removeReminder={props.removeReminder}
            handleUpdate={(vals) => { setIsModalOpen(false); props.update(vals) }}
            defaultValues={{
              dueDate: dueDate,
              textInput: reminder.textInput,
              id: reminder.id,
              complete,
            }} />
        </div></Modal>
    </React.Fragment >
  );
}

const ReminderForm = (props: {
  handleUpdate: (reminder: ReminderUpdate) => void,
  handleDateChange?: (date: string) => void,
  removeReminder?: (props: { id: string }) => void,
  saveLabel: string,
  deleteLabel?: string,
  defaultValues: {
    dueDate: string,
    textInput: string,
    id?: string,
    complete?: boolean,
  },
}) => {
  const current = format(new Date(), 'yyyy-MM-dd');
  const { handleUpdate, defaultValues } = props;
  const formik = useFormik<CreateReminder>({
    initialValues: defaultValues,
    validateOnBlur: true,
    validate: (values) => {
      values.dueDate && props.handleDateChange?.(values.dueDate);
      if (current > values.dueDate && values.textInput) {
        return Promise.resolve({
          dueDate: 'Cannot add a reminder in the past'
        })
      }
    },

    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      handleUpdate({
        ...values,
        complete: defaultValues.complete ?? false,
        id: defaultValues.id ?? uuidv4(),
      });
      setSubmitting(false);
      resetForm({
        values: {
          dueDate: values.dueDate,
          textInput: ""
        }
      })
    }
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        fullWidth
        label={"Current Date"}
        id="dueDate"
        type="date"
        error={!!formik.errors.dueDate}
        value={formik.values.dueDate}
        onChange={formik.handleChange}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <br />
      <TextField
        fullWidth
        label={"Note"}
        id="textInput"
        required
        error={!!formik.errors.textInput}
        value={formik.values.textInput}
        onChange={formik.handleChange}
        name="textInput" type="text" />
      <Grid container spacing={2}>
        {props.deleteLabel && !props.defaultValues.complete &&
          <Grid item xs>
            <Button
              variant="contained"
              style={{ marginTop: '10px' }}
              fullWidth
              color={"secondary"} onClick={() => props.removeReminder?.({ id: defaultValues.id || '' })}>{props.deleteLabel}</Button>
          </Grid>}
        <Grid item xs>
          <Button
            color="primary"
            variant="contained"
            style={{ marginTop: '10px' }}
            fullWidth
            type="submit">{props.saveLabel}</Button>
        </Grid>

      </Grid>

    </form>
  );
}

export default App;
